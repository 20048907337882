import * as React from 'react'
import Logo from '../../images/oldLogo.svg'
import { Link } from 'gatsby'
const terms = () => {
  return <div className='px-7.5 py-7.5 overflow-hidden text-gotin bg-gotin text-center'>
    <div className='flex justify-center'>
      <img src={Logo} alt="gotin" className='w-45' />
    </div>
    <h2 className="text-center my-5 text-xlg font-semibold">使用条款</h2>
    <p className="whitespace-pre-wrap text-gotin py-2.5 px-1">请仔细阅读、充分理解下列条款和条件，特别是免除或者限制责任的条款、争议解决和法律适用条款。免除或者限制责任的条款将以粗体字显示，您应重点阅读。</p>

    <p className="whitespace-pre-wrap text-gotin py-2.5 px-1">本《用户服务协议》（以下简称为“本协议”）适用于您访问和使用北京有大科技有限公司的官方网站（<span className='text-gotinc'><Link to="/" >https://www.gotin.online</Link></span> ）以及有大承办的各类线上活动的网站、APP、微信公众号等。本协议是您（以下简称为“您”或“用户”）与【北京有大科技有限公司】（以下简称为“我们”或“有大”之间的具有法律约束力的协议。前述“用户”包括参展商用户及平台用户。</p>

    <h4 className="font-semibold my-2.5">1.本协议的适用和接受</h4>

    <p className="whitespace-pre-wrap text-gotin py-2.5 px-1">1.1 您访问有大官方网站及有大承办的各类线上活动的网站、APP、微信公众号等（以下简称“有大相关网站”）和使用上述网站及平台所提供的各种工具、内容和服务（以下统称为“有大服务”），包括但不限于对有大相关网站的访问、登录，对上述内容的浏览和使用，通过上述网站及平台上传、展示展品、装修网上店铺、进行视频直播、网上营销，在上述网站及平台注册账号、查询展品、开展询盘沟通等行为，表示您已充分阅读、理解并自愿接受本协议的全部内容并受其约束。如果您不接受本协议内容，您应当立即停止访问上述网站及平台和/或使用有大服务。<br/>

    1.2 本协议内容包括本协议正文以及有大相关网站不时发布的隐私协议、Cookies政策、展品上传政策、免责声明、版权声明及任何其他规则、政策、声明、通知、警示、提示、说明（以下简称为“有大规则”）。前述规则为本协议不可分割的组成部分，与本协议具有同等法律效力。<br/>

    1.3 如果根据中华人民共和国法律和/或其他适用法律（包括您居住的国家/地区法律）：（1）您不具备完全民事权利能力和与所从事的民事行为相适应的行为能力，且未就访问有大相关网站和使用有大服务征得您法定监护人的同意或者（2）您被禁止访问有大相关网站和/或使用有大服务，则您不得访问有大相关网站和/或使用有大服务。否则，您及/或您的监护人应承担因此而导致的一切后果，且我们有权注销您的账户，并向您及/或您的监护人索赔。如您代表一家企业、组织机构或其他法律主体使用有大服务，则您声明和保证，您已经获得充分授权并有权代表该企业、组织机构或法律主体使用有大服务，并受本协议约束。<br/>

    1.4 我们可随时修订或更新本协议的全部或部分内容，且任何修订或更新将在其发布时生效。变更后的协议将会在有大相关网站公布，因此，您应定期浏览本协议，以查看有关变更。您在本协议变更后继续访问有大相关网站或使用有大服务，即表示您同意接受经修订或更新的协议。如果您不同意相关变更，您应当立即停止访问有大相关网站或使用有大服务。<br/>

    1.5 如果我们发布或提供了本协议的英文版本，您同意该等英文版本仅为方便您阅读使用。英文版本与中文版本不一致的，以中文版本为准。<br/>

    1.6 您可能需要与我们或我们的关联公司就任何服务（或服务中的功能）（无论是在线还是离线）签订单独的协议（统称为“附加协议”）。如果本协议与附加协议之间存在任何冲突或不一致，则仅就该服务（或服务中的功能）而言，附加协议优先于本协议适用。<br/>
    </p>

    <h4 className="font-semibold my-2.5">2.账户及服务</h4>
    <p className="whitespace-pre-wrap text-gotin py-2.5 px-1">
      2.1 参展企业<br/>

      2.1.1 参展企业可通过有大和/或相关方平台注册账户登录有大相关网站及使用有大服务。<br/>

      2.1.2 首次通过参展企业账户登录有大相关网站需通过预留或填写的邮箱进行验证。参展企业用户可建立账号上传及维护展示资料、调整展示内容、收集用户名片等服务。<br/>

      2.2 平台用户<br/>

      2.2.1 平台用户可通过有大注册账户登录有大相关网站及使用有大服务。<br/>

      2.3 在您申请注册账户后，我们将对您的注册信息进行审核，并可能出于任何原因拒绝您的注册申请、限制或封禁未通过审核的账户。<br/>

      2.4 在注册、使用和管理账户时，您保证您提交的材料或填写的信息真实、准确及完整。依照适用中国法律法规的规定，为使用有大服务的部分功能，您需要填写真实的身份信息。请您按照相关法律规定完成实名认证，并注意及时更新上述相关信息。若我们发现或有合理理由怀疑您提交的材料或提供的信息不真实、不准确、不完整或不合法，我们有权拒绝您的注册申请或停止为您提供相关服务，您可能无法使用有大服务或在使用过程中部分功能受到限制。<br/>

      2.5 您应当确保您不是任何国家、国际组织或地区实施的贸易限制、制裁或其他法律、规则限制的对象，否则您可能无法正常注册及使用有大服务。<br/>

      2.6 您仅获得相关账户的使用权，不得将账户转让、出租、出售或授权给任何第三方。如果我们发现或者有理由认为使用者并非账户初始注册人，为保障账户安全，我们有权立即暂停或终止向该注册账户提供服务，并有权永久禁用该账户。<br/>

      2.7 您有责任维护您的账户、密码的安全性与保密性，并对您以注册账户名义所从事的活动承担全部法律责任。您应高度重视对账户与密码的保密，在任何情况下不向他人透露账户及密码。若发现他人未经许可使用您的账户或发生任何其他安全漏洞时，您应当立即通知我们并更改密码。如果有理由怀疑第三方知道您的密码，或可能存在滥用的情形，我们有权在该情况得到澄清之前封锁您的账户或为安全目的采取其他必要措施。您知悉并同意，我们不对不可归责于我们的，因任何未经授权的人所从事的任何活动或用户账户信息的数据泄露而引起的或与之相关的任何损失或损害负责。<br/>

      2.8 您理解并同意，我们提供的有大服务是按照现有技术和条件所能达到的现状提供的。我们会尽最大努力向您提供服务，确保服务的连贯性和安全性。您理解，我们不能随时或始终预见和防范法律、技术以及其他风险，包括但不限于不可抗力、网络原因、第三方服务瑕疵、第三方网站等原因可能导致的服务中断、不能正常访问有大相关网站或使用有大服务以及其他损失和风险。<br/>

      2.9 您理解并同意，为了整体服务运营、平台运营安全的需要，我们有权视具体情况决定服务/功能的设置及其范围，修改、中断、中止或终止有大相关网站的全部或部分功能或服务。<br/>
    </p>

    <h4 className="font-semibold my-2.5">3.用户信息的保护</h4>

    <p className="whitespace-pre-wrap text-gotin py-2.5 px-1">
      3.1 对于您的个人信息，包括但不限于您的姓名、手机号码、电子邮箱地址等，我们将严格按照我们发布的《隐私协议》进行收集、使用、保存、共享、转让、公开披露以及保护。请您仔细阅读我们的《隐私协议》。<br/>

      3.2 您理解并同意，我们将通过有大相关网站或在提供有大服务中收集您个人信息以外的其他信息，包括但不限于企业信息、直播数据、展示洽谈平台数据等；我们可能会从第三方间接获得前述相关数据；我们将对直接或间接收集的信息进行统计分析，以优化展品展示及直播效果、实现智能推送、进行后续研究或进行商业上合理范围内的利用；我们将根据相关法律法规、法律程序、相关政府机关的强制性要求以及向您提供服务的需要，公开披露该等信息或将该等信息与第三方分享。<br/>
    </p>

    <h4 className="font-semibold my-2.5">4.第三方网站及应用</h4>

    <p className="whitespace-pre-wrap text-gotin py-2.5 px-1">
      4.1 有大相关网站可能包含指向外部网站的链接。用户应仔细阅读这些外部网站的使用条款及隐私协议。我们不对外部网站的真实性、合法性或安全性（包括数据泄露等）承担任何责任，且我们与该等超链接或网站没有任何关联，也不代表该等超链接或网站。<br/>

      4.2 您在访问有大相关网站或使用有大服务中使用第三方提供的应用、软件或服务时，除遵守有大相关规则外，还可能需要同意并遵守第三方的协议或规则。如因第三方应用、软件及相关服务产生的争议、损失或损害，由您自行与第三方解决，我们并不就此而对您或任何第三方承担任何责任。<br/>
    </p>

    <h4 className="font-semibold my-2.5">5.用户行为规范</h4>

    <h4 className="font-semibold my-2.5">5.1 一般行为规范</h4>

    <p className="whitespace-pre-wrap text-gotin py-2.5 px-1">您同意在访问有大相关网站及使用有大服务时遵守所有适用的法律法规，并对您访问有大相关网站及使用有大服务的行为负责。您访问有大相关网站及/或使用有大服务时不得从事下列行为：</p>

    <p className="whitespace-pre-wrap text-gotin py-2.5 px-1">
      （1）假冒任何人或实体，歪曲自己与任何人或实体的隶属关系；<br/>

      （2）非法侵入网络、干扰网络正常功能、窃取网络数据等危害网络安全的活动；<br/>

      （3）提供专门用于从事侵入网络、干扰网络正常功能及防护措施、窃取网络数据等危害网络安全活动的程序、工具；<br/>

      （4）明知他人从事危害网络安全的活动的，为其提供技术支持、广告推广、支付结算等帮助；<br/>

      （5）使用未经我们授权或许可的任何插件、外挂、系统或第三方工具对有大相关网站及有大服务的正常运行进行控制、干扰、破坏、修改或施加其他影响；<br/>

      （6）使用未经许可的数据或进入未经许可的服务器/账户；<br/>

      （7）未经允许对计算机信息网络中存储、处理或者传输的数据和应用程序进行删除、修改或者增加的；<br/>

      （8）故意制作、传播计算机病毒等破坏性程序；<br/>

      （9）违反法律法规、规章、政策、规范性文件、本协议及侵犯他人合法权益的其他行为。<br/>
    </p>

    <h4 className="font-semibold my-2.5">5.2 展品上传规范</h4>

    <p className="whitespace-pre-wrap text-gotin py-2.5 px-1">参展商用户通过有大相关网站上传展品，应当符合适用的法律法规并遵守我们发布的《展品上传手册》的规范。参展商用户应当仔细阅读我们的《展品上传手册》。</p>

    <h4 className="font-semibold my-2.5">5.3 信息内容展示规范</h4>

    <p className="whitespace-pre-wrap text-gotin py-2.5 px-1">您制作、复制、发布、传播的信息应当符合适用法律法规的要求。您同意并承诺不制作、复制、发布、传播下列信息：</p>

    <p className="whitespace-pre-wrap text-gotin py-2.5 px-1">
      （1）反对中华人民共和国宪法所确定的基本原则的；<br/>

      （2）危害中华人民共和国国家安全，泄露中华人民共和国国家秘密，颠覆中华人民共和国国家政权，破坏中华人民共和国国家统一的；<br/>

      （3）损害中华人民共和国国家荣誉和利益的；<br/>

      （4）煽动中华人民共和国民族仇恨、民族歧视，破坏中华人民共和国民族团结的；<br/>

      （5）破坏中华人民共和国国家宗教政策，宣扬邪教和封建迷信的；<br/>

      （6）散布谣言，扰乱社会秩序，破坏社会稳定的；<br/>

      （7）散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；<br/>

      （8）侮辱或者诽谤他人，侵害他人合法权益的；<br/>

      （9）歪曲、丑化、亵渎、否定英雄烈士事迹和精神，以侮辱、诽谤或者其他方式侵害英雄烈士的姓名、肖像、名誉、荣誉的；<br/>

      （10）宣扬恐怖主义、极端主义或者煽动实施恐怖活动、极端主义活动的；<br/>

      （11）损害中华人民共和国国家机关信誉的；<br/>

      （12）任何假冒伪劣商品或服务的信息，包括但不限于侵犯他人知识产权的商品、不符合相关国家标准或行业标准的商品、虚假服务等信息；<br/>

      （13）其他违反适用法律法规、政策及公序良俗、干扰有大相关网站正常运营或侵犯其他用户或第三方合法权益内容的信息；<br/>

      （14）包含任何直接或间接指向任何其他网站的链接，其中包含任何可能违反本协议的内容；<br/>

      （15）在有大相关网站以任何形式陈列、展示其他展览会、专业批发市场或其他提供卖家注册和第三方贸易撮合平台功能的电子商务网站的资料；<br/>

      （16）在有大相关网站为其他展览会、专业批发市场或电子商务平台进行任何形式的宣传活动。<br/>
    </p>

    <h4 className="font-semibold my-2.5">5.4 信息内容使用规范</h4>

    <p className="whitespace-pre-wrap text-gotin py-2.5 px-1">未经我们事先书面许可，任何用户均不得自行或授权、允许、协助他人对有大相关网站及有大服务中的信息内容（包括但不限于参展商信息、展品信息、采购商信息、统计数据）进行如下行为：</p>

    <p className="whitespace-pre-wrap text-gotin py-2.5 px-1">
      （1）复制、下载、读取、编译、采用有大相关网站及有大服务的信息内容，用于包括但不限于宣传、增加阅读量、浏览量等商业用途；<br/>

      （2）擅自编辑、整理、编排有大相关网站及有大服务的信息内容后在有大相关网站及有大服务的源页面以外的渠道进行展示；<br/>

      （3）采用包括但不限于特殊标识、特殊代码等任何形式的识别方法，自行或协助第三人对有大相关网站及有大服务的信息内容产生流量、阅读量引导、转移、劫持等不利影响；<br/>

      （4）以任何方式（包括但不限于盗链、冗余盗取、非法抓取、以数据爬虫技术获取、模拟下载、深度链接、假冒注册等）直接或间接盗取有大相关网站或有大服务的视频、图文等信息内容，或以任何方式（包括但不限于隐藏或者修改域名、平台特有标识、用户名等）删除或改变相关信息内容的权利管理电子信息；<br/>

      （5）其他非法获取或使用有大相关网站及有大服务的信息内容的行为。<br/>
    </p>

    <h4 className="font-semibold my-2.5">5.5广告</h4>

    <p className="whitespace-pre-wrap text-gotin py-2.5 px-1">
      5.5.1 参展企业用户使用有大相关网站开展广告推广服务时，应当确保广告内容真实、准确，不含有虚假或者引人误解的内容，符合《中华人民共和国广告法》、《反不正当竞争法》及其他适用法律法规的规定。<br/>

      5.5.2 广告不得有下列情形：<br/>
    </p>

    <p className="whitespace-pre-wrap text-gotin py-2.5 px-1">
      （1）使用或者变相使用中华人民共和国的国旗、国歌、国徽，军旗、军歌、军徽；<br/>

      （2）使用或者变相使用中华人民共和国国家机关、国家机关工作人员的名义或者形象；<br/>

      （3）使用“国家级”、“最高级”、“最佳”等用语；<br/>

      （4）损害中华人民共和国国家的尊严或者利益，泄露中华人民共和国国家秘密；<br/>

      （5）妨碍社会安定，损害社会公共利益；<br/>

      （6）危害人身、财产安全，泄露个人隐私；<br/>

      （7）妨碍社会公共秩序或者违背社会良好风尚；<br/>

      （8）含有淫秽、色情、赌博、迷信、恐怖、暴力的内容；<br/>

      （9）含有民族、种族、宗教、性别歧视的内容；<br/>

      （10）妨碍环境、自然资源或者文化遗产保护；<br/>

      （11）法律、行政法规规定禁止的其他情形。<br/>
    </p>

    <p className="whitespace-pre-wrap text-gotin py-2.5 px-1">
      5.5.3 发布各类广告，以及适用法律、行政法规规定应当进行审查的其他广告，应当在发布前由广告审查机关对广告内容进行审查；未经审查，不得发布。<br/>

      5.5.4 参展商及平台用户理解并同意，在您访问有大相关网站及使用有大服务过程中，我们可能会向您推送具有相关性的参展商、展品或广告信息。<br/>

      5.5.5 对于广告、推送或推广信息，参展商及平台用户应当自行判断该广告或推广信息的真实性和可靠性并为自己的判断负责。除法律法规明确规定外，您应自行承担因该广告或推广信息进行的磋商、交易或因前述内容遭受的损害或损失，我们不予承担责任。<br/>
    </p>

    <h4 className="font-semibold my-2.5">6.知识产权</h4>

    <p className="whitespace-pre-wrap text-gotin py-2.5 px-1">
      6.1 您访问有大相关网站及使用有大服务，应仔细阅读我们发布的知识产权相关规定并受其约束。<br/>

      6.2 我们是有大相关网站及有大服务（包括但不限于软件、技术、程序、网页、文字、图片、图像、音频、视频、图表、版面设计、电子文档等）的知识产权所有者或合法被许可人。未经我们许可，任何人不得擅自使用（包括但不限于通过任何机器人、“蜘蛛”、爬虫等程序或设备监视、复制、传播、展示、镜像、上载、下载）有大相关网站及有大服务中的内容。<br/>

      6.3 您在任何情况下都不得擅自使用我们的包括但不限于“有大科技 Yoda Technology”、“有了平台 Gotin”、“<span className='text-gotinc'><Link to="/" >https://www.gotin.online</Link></span>”及有大承办的各类活动的合作方在内的任何商标、服务标记、商号、域名、网站名称或其他显著品牌特征等（以下统称为“标识”）。未经我们事先书面同意，您不得将本条款前述标识以任何单独或结合方式展示、使用或申请注册商标、进行域名注册等，也不得向他人明示或暗示您有权展示、使用、或以其他方式有权处理该等标识。由于您违反本协议使用我们上述商标、标识等给我们或他人造成损失的，由您承担全部法律责任。<br/>

      6.4 您理解并承诺，您在使用有大相关网站及有大服务时上传、发布或传输的内容（包括但不限于文字、图片、视频、音频等各种形式的内容及其中包含的音乐、声音、台词、视觉设计、对话等所有组成部分）均由您原创或已获合法授权（且含转授权）。您通过有大相关网站上传、发布所产生内容的知识产权归属您或原始著作权人所有。<br/>

      6.5 除非我们另行明确说明，您知悉、理解并同意，您通过有大相关网站上传、发布或传输的内容（包括但不限文字、图像、音频、视频、直播内容等各种形式的内容及其中包括的音乐、声音、台词、视觉设计、对话等所有组成部分），您授予我们及我们的关联方一项全球范围内、免费、非独占、不可撤销的、可再许可（通过多层次）的权利（包括但不限于复制权、翻译权、汇编权、信息网络传播权、改编权及制作衍生品、表演和展示的权利等），上述权利的使用范围包括但不限于在当前或其他网站、应用程序或终端设备等使用。您在此确认并同意，我们有权自行或许可第三方在与上述内容、有大相关网站及有大服务有关的任何宣传、推广、广告、营销和/或研究中使用和以其他方式开发内容（全部或部分）。为避免疑义，您理解并同意，上述授予的权利包括使用、复制和展示您拥有或被许可使用植入内容中的个人形象、肖像、姓名、商标、服务标志、品牌、名称、标识和公司标记以及任何其他品牌、营销或推广素材等的权利和许可。<br/>

      6.6 我们建立知识产权投诉渠道。您在访问有大相关网站及使用有大服务时发现其他参展企业侵犯您的知识产权的，您可以联系我们进行处理。<br/>
    </p>

    <h4 className="font-semibold my-2.5">7.用户违反本协议的后果</h4>

    <p className="whitespace-pre-wrap text-gotin py-2.5 px-1">
      7.1 如您违反本协议，我们有权独立判断并视情况采取预先警示、停止服务，删除相关内容、限制账户部分或者全部功能、终止提供服务、封禁账户等措施，对于因此而造成您无法正常使用账户及有大服务等后果，我们不承担任何责任。我们有权在合理范围内公告处理结果，且有权根据实际情况决定是否恢复相关账户的使用。对涉嫌违反法律法规、涉嫌违法犯罪的行为，我们将保存有关记录，并有权依法向有关主管部门报告、配合有关主管部门调查、向公安机关报案等。对已删除内容我们有权不予恢复。<br/>

      7.2 因您违反本协议引起第三方投诉、诉讼或索赔的，您应当自行处理并承担可能因此产生的全部法律责任。因您违反本协议导致我们及/或我们的关联方向任何第三方赔偿或遭受国家机关处罚的，您还应足额赔偿我们及/或我们关联方因此遭受的全部损失。<br/>
    </p>

    <h4 className="font-semibold my-2.5">8.免责声明</h4>

    <p className="tips">
        您访问有大相关网站及使用有大服务前，请仔细阅读我们发布的《免责声明》。我们对《免责声明》里所列事项不承担任何形式的法律责任。<br/>

        北京有大科技有限公司（以下简称为“有大”）网站（<span className='text-gotinc'><Link to="/" >https://www.gotin.online</Link></span>）及承办活动的网站、相关移动网站、微信公众号、小程序及移动应用程序以及不时由我们拥有、运营、品牌化或提供的其他门户网站（以下统称为“有大相关网站”）的部分栏目（如“展商展品查询”、“名片”）中所涉及的公司和产品信息（包括但不限于公司名称、联系人、产品的描述和说明，相关图片、视频等）均由参展商自行提供，参展商依法对其信息承担全部责任。为向用户提供优质服务，我们将努力确保有大相关网站信息的真实、合法，但我们不对此等信息的准确性、完整性、合法性、真实性、适用性等承担任何形式的责任。<br/>

        我们及有大相关网站对任何使用或提供有大相关网站信息的商业活动及由此而引致之任何意外、疏忽、纠纷（包括但不限于诽谤、合同纠纷、知识产权争议等）及其所造成的损失（包括因下载而感染电脑病毒），不承担任何形式的法律责任。如因您的任何行为而导致我们的任何损害或损失，我们保留追究您一切法律责任的权利。<br/>

        对于下述原因导致或有关的任何损失，我们在法律法规允许的范围内免于承担责任：<br/>
    </p>

    <p className="whitespace-pre-wrap text-gotin py-2.5 px-1">
      (1) 因政府行为、司法行政命令、自然灾害、战争、罢工、骚乱等不能预见、不能克服且不能避免的不可抗力因素；<br/>

      (2) 因电力供应故障、通讯网络故障等公共服务因素或第三人因素；<br/>

      (3) 在我们已尽善意管理的情况下，因常规或紧急的设备与系统维护、设备与系统故障、网络信息与数据安全等因素。<br/>
    </p>

    <p className="whitespace-pre-wrap text-gotin py-2.5 px-1">
      在任何情况下，我们均不对任何间接性、后果性、惩罚性、偶然性、特殊性的损害承担责任。<br/>

      有大相关网站可能包含指向第三方网站的超链接以及由第三方提供的应用、软件或服务。您应仔细阅读这些第三方网站、应用、软件或服务的使用条款及隐私政策。我们不对第三方网站、应用、软件或服务的真实性、合法性或安全性问题（包括数据泄露等）承担任何责任。如因该等第三方网站、应用、软件或服务产生任何争议、损失或损害，应由您自行与第三方解决。<br/>

      本声明以及其修改权、更新权及最终解释权均属中国对外贸易中心。<br/>

      若本声明英文版与中文版不一致，则以中文版为准。<br/>
    </p>

    <h4 className="font-semibold my-2.5">9.法律适用及争议解决</h4>

    <p className="whitespace-pre-wrap text-gotin py-2.5 px-1">本协议适用中华人民共和国（为本协议之目的，不包含香港、澳门及台湾地区）法律并依其解释。您同意就因本协议或您对有大相关网站及有大服务的使用而产生或与之相关的与我们之间的任何争议、权利主张或案由，包括与本协议的存在或有效性相关的任何争议，均应由双方协商解决。协商不成的，提交我们住所地的有管辖权的人民法院管辖。</p>

    <h4 className="font-semibold my-2.5">10.其他</h4>

    <p className="whitespace-pre-wrap text-gotin py-2.5 px-1">
      10.1 本协议中的标题仅为方便阅读而设，并不影响本协议中任何规定的含义或解释。<br/>

      10.2 您和我们均是独立的主体，在任何情况下本协议不构成我们对您的任何形式的明示或暗示担保或条件，双方之间亦不构成代理、合伙、合营或雇佣关系。<br/>

      10.3 如果本协议的任何条款被认定为无效或无法执行，则该条款的无效或无法执行不影响其他条款的效力，其余条款应保持有效并得到执行。<br/>
    </p>

  </div>
}
export default terms